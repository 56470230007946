import React from "react";
import { type LottieComponentProps } from "lottie-react";

const LazyLottieComponent = React.lazy(() => import("lottie-react"));

interface LottieProps<T extends Record<string, unknown>> {
  animationData: T;
  fallback: React.ReactNode;
  id: string;
}

function LazyLottie<T extends Record<string, unknown>>({
  animationData,
  fallback,
  id,
  ref,
  ...props
}: LottieProps<T> & Omit<LottieComponentProps, "animationData">) {
  const [data, setData] = React.useState<any>(null);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      (async () => {
        await import("lottie-react"); // Trigger the library lazy load even if the animationData is not ready
        setData(animationData);
      })();
    }
  }, [animationData]);

  if (!data) return fallback || <div />;

  return (
    <React.Suspense fallback={fallback || <div />}>
      <LazyLottieComponent
        style={{
          maxWidth: props.width,
          maxHeight: props.height,
          width: "100%",
          height: "100%",
        }}
        animationData={data}
        {...props}
      />
    </React.Suspense>
  );
}

export { LazyLottie };
