"use client";

import React from "react";
import Image from "next/image";
import { useTranslations } from "next-intl";
import lottieStep1 from "@/public/staticfiles/unblock/anim_1.json";
import lottieStep2 from "@/public/staticfiles/unblock/anim_2.json";
import lottieStep3 from "@/public/staticfiles/unblock/anim_3.json";
import step1 from "@/public/staticfiles/unblock/unblock-step-1-lottie-fallback-min.png";
import step2 from "@/public/staticfiles/unblock/unblock-step-2-lottie-fallback-min.png";
import step3 from "@/public/staticfiles/unblock/unblock-step-3-lottie-fallback-min.png";
import { useDeviceInfoContext } from "@/processes/device-info";
import { useMediaQuery } from "@/shared/lib/hooks";
import { LazyLottie } from "@/shared/ui/LazyLottie/LazyLottie";
import UnblockVideoPlayer from "./UnblockVideoPlayer";
import * as SC from "./styled";

function UnblockGuide() {
  const t = useTranslations();
  const { isMobile } = useDeviceInfoContext();

  const isSmallScreen = useMediaQuery("(max-width: 900px)");

  const isMobileView = isMobile || isSmallScreen;

  return (
    <SC.Wrapper>
      <div>
        <h1>{t("login.instructions.instructions_title")}</h1>
        <p>{t("login.instructions.instructions_subtitle")}</p>
        {isMobileView && (
          <SC.MobileViewUnblockVideoWrapper>
            <UnblockVideoPlayer isMobile />
            <span>{t("login.instructions.or_manual_instruction")}</span>
          </SC.MobileViewUnblockVideoWrapper>
        )}
        <SC.UnblockStepsList>
          <SC.UnblockStep>
            <span>{t.rich("login.instructions.step_number", { step: 1 })}</span>
            <LazyLottie
              animationData={lottieStep1}
              fallback={<Image src={step1} alt="" width={220} height={110} />}
              loop
              id="unblock_lottie_1"
              width={220}
              height={110}
            />
            <div>
              <SC.UnblockStepTitle>
                {t("login.instructions.instructions_step_1_title")}
              </SC.UnblockStepTitle>
              <SC.UnblockStepText>
                {t("login.instructions.instructions_step_1_text")}
              </SC.UnblockStepText>
            </div>
          </SC.UnblockStep>{" "}
          <SC.UnblockStep>
            <span>{t.rich("login.instructions.step_number", { step: 2 })}</span>
            <LazyLottie
              animationData={lottieStep2}
              fallback={<Image src={step2} alt="" width={220} height={110} />}
              loop
              id="unblock_lottie_2"
              width={220}
              height={110}
            />{" "}
            <div>
              <SC.UnblockStepTitle>
                {t("login.instructions.instructions_step_2_title")}
              </SC.UnblockStepTitle>
              <SC.UnblockStepText>
                {t("login.instructions.instructions_step_2_text")}
              </SC.UnblockStepText>
            </div>
          </SC.UnblockStep>{" "}
          <SC.UnblockStep>
            <span>{t.rich("login.instructions.step_number", { step: 3 })}</span>
            <LazyLottie
              animationData={lottieStep3}
              fallback={<Image src={step3} alt="" width={220} height={110} />}
              loop
              id="unblock_lottie_3"
              width={220}
              height={110}
            />{" "}
            <div>
              <SC.UnblockStepTitle>
                {t("login.instructions.instructions_step_3_title")}
              </SC.UnblockStepTitle>
              <SC.UnblockStepText>
                {t("login.instructions.instructions_step_3_text")}
              </SC.UnblockStepText>
            </div>
          </SC.UnblockStep>{" "}
        </SC.UnblockStepsList>
      </div>
      <SC.VerticalDivider />
      {!isMobileView && <UnblockVideoPlayer isMobile={false} />}
    </SC.Wrapper>
  );
}

export default UnblockGuide;
