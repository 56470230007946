import { internalization } from "@/shared/lib/constants";

const UNBLOCK_INSTRUCTIONS_BY_LANGUAGE: {
  [key in (typeof internalization.LANGS_LIST)[number]]: string;
} = {
  "zh-CN": "https://www.youtube.com/embed/xVLC9u03_kU",
  "zh-TW": "https://www.youtube.com/embed/xVLC9u03_kU",
  vi: "https://www.youtube.com/embed/6BI5cYg1F3o",
  ru: "https://www.youtube.com/embed/Y9deilh3izc",
  tr: "https://www.youtube.com/embed/f8szv-6h6_g",
  pt: "https://www.youtube.com/embed/77K_a3r0Y0A",
  ko: "https://www.youtube.com/embed/QXKJVVfMeDo",
  it: "https://www.youtube.com/embed/Z_7qCaMjkgU",
  fr: "https://www.youtube.com/embed/nzR-SV1OPZI",
  es: "https://www.youtube.com/embed/09vjSrDWHMQ",
  en: "https://www.youtube.com/embed/6BI5cYg1F3o",
  de: "https://www.youtube.com/embed/NWmWuI_m6D4",
  ar: "https://www.youtube.com/embed/iNGv2B6SFGo",
};

export { UNBLOCK_INSTRUCTIONS_BY_LANGUAGE };
